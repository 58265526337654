import axios from 'axios';
import { END_POINT } from './consts';
import { store } from 'app/store';
import { AsyncAPI } from 'app/AsyncAPI/AsyncAPI';

interface IPostData {
  [key: string]: any;
}

class API {
  async get(path: string, params = {}, headers = {}) {
    return await axios.get(END_POINT + path, {
      params,
      ...headers,
      withCredentials: true,
    });
  }
  async post(path: string, postdata: IPostData, params = {}, headers = {}) {
    return await axios.post(END_POINT + path, JSON.stringify(postdata), {
      params,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
        'ws-client-id': AsyncAPI.id,
      },
      withCredentials: true,
    });
  }
  async patch(path: string, postdata: IPostData, params = {}, headers = {}) {
    return await axios.patch(END_POINT + path, JSON.stringify(postdata), {
      params,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
        'ws-client-id': AsyncAPI.id,
      },
      withCredentials: true,
    });
  }
  async delete(path: string, params = {}, headers = {}) {
    const dialogueId = store.getState().dialogue.dialogue.id;
    const dialoguePath = dialogueId != null ? `?dialogueId=${dialogueId}` : '';

    return await axios.delete(END_POINT + path + dialoguePath, {
      params,
      headers: {
        ...headers,
        'ws-client-id': AsyncAPI.id,
      },
      withCredentials: true,
    });
  }
}

const api = new API();
export default api;
